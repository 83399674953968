import get from 'lodash/get'
import React from 'react'
import Disqus from 'disqus-react'

import {
  SideBar,
  ScrollToTopOfPage,
  ContentfulSitePost,
} from 'blog-components'
import Layout from '../components/Layout'

// eslint-disable-next-line
import SiteInformation from '../fragments/SiteInformationFragment'
// eslint-disable-next-line
import BlogPostDetail from '../fragments/BlogPostDetailFragment'

import { graphql } from 'gatsby'

class BlogPostContentfulTemplate extends ScrollToTopOfPage {
  render() {
    const site = get(this, 'props.data.site')

    const title = get(this, 'props.data.post.title')
    const authors = get(this, 'props.data.post.authors')
    const description = get(
      this,
      'props.data.post.description.childMarkdownRemark.rawMarkdownBody'
    )
    const slug = get(this, 'props.data.post.slug')
    const publishDate = get(this, 'props.data.post.publishDate')
    const createdAt = get(this, 'props.data.post.createdAt')
    const updatedAt = get(this, 'props.data.post.updatedAt')
    const shareImage = get(this, 'props.data.post.shareImage.fixed.src')
    const htmlAst = get(
      this,
      'props.data.post.body.childMarkdownRemark.htmlAst'
    )
    const categories = get(this, 'props.data.post.categories')
    const relatedPosts = get(this, 'props.data.post.relatedPosts')

    const disqusConfig = {
      url: site.siteMetadata.siteUrl + slug,
      identifier: slug,
      title: title,
    }

    return (
      <Layout>
        <div className="container fadein-3 blog-post" data-emergence="visible">
          <div className="row">
            <div className="articles col-md-9">
              <ContentfulSitePost
                slug={slug}
                title={title}
                authors={authors}
                publishDate={publishDate}
                createdAt={createdAt}
                updatedAt={updatedAt}
                shareImage={shareImage}
                description={description}
                categories={categories}
                htmlAst={htmlAst}
                site={site}
              />
            </div>
            <div className="sidebar col-lg-2">
              <SideBar relatedPosts={relatedPosts} />
            </div>
          </div>

          <div className="row">
            <div className="articles col-md-9">
              <Disqus.DiscussionEmbed
                shortname={site.siteMetadata.disqusShortname}
                config={disqusConfig}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostContentfulTemplate

export const pageQuery = graphql`
  query ContentfulBlogPostByPath($path: String!) {
    site {
      ...SiteInformation
    }
    post: contentfulBlogPost(slug: { eq: $path }) {
      ...BlogPostDetail
    }
    allContentfulBlogPost(sort: { order: DESC, fields: [publishDate] }) {
      posts: edges {
        post: node {
          slug
          title
        }
      }
    }
  }
`
